<template>
  <n-spin :show="sending">
    <n-list>
      <template #header>
        <n-h2 class="mb-0">{{ t("publications.faqs.questions.title") }}</n-h2>
      </template>
      <n-list-item>
        <template #suffix>
          <n-button
            type="primary"
            @click="addQuestion"
            :disabled="!isAuthenticated || question.length === 0"
            >{{ t("commons.actions.send") }}</n-button
          >
        </template>
        <n-input
          class="pt-2"
          type="textarea"
          v-model:value="question"
          :placeholder="
            isAuthenticated
              ? t('publications.faqs.questions.form.question.placeholderForSignedUsers')
              : t('publications.faqs.questions.form.question.placeholderForUnsignedUsers')
          "
          :disabled="!isAuthenticated"
        />
      </n-list-item> </n-list
  ></n-spin>
</template>
<style lang="scss" scoped>
.n-grid {
  text-align: left;
}
</style>

<script>
import { defineComponent, ref, computed } from "vue";
import {
  NList,
  NListItem,
  NButton,
  NInput,
  NH2,
  useMessage,
  NSpin,
  useDialog,
} from "naive-ui";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "Input",
  components: { NList, NListItem, NButton, NInput, NH2, NSpin },
  props: {
    publication: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    const store = useStore();
    const dialog = useDialog();
    const questionRef = ref("");
    const message = useMessage();
    const sendingRef = ref(false);
    return {
      t,
      addQuestion: () => {
        sendingRef.value = true;
        store
          .dispatch("questions/checkContent", questionRef.value)
          .then((result) => {
            if (result.pass) {
              store
                .dispatch("questions/createQuestion", {
                  question: questionRef.value,
                  publicationID: props.publication.id,
                  relatedUsers: [store.getters["auth/user_id"], props.publication.user],
                })
                .then(async () => {
                  message.success("Question sent");
                  await store.dispatch("notifications/FireNotification", {
                    userId: props.publication.user,
                    fromUserId: store.getters["auth/user_id"],
                    title: t(
                      "publications.faqs.questions.notifications.newQuestionReceived.title",
                      { publicationTitle: props.publication.title }
                    ),
                    message: questionRef.value,
                    avatar: {
                      publicationID: props.publication.id,
                      publicationUser: props.publication.user,
                      defaultImage: props.publication.orderPhotos[0],
                    },
                    targetRoute: {
                      name: "questions",
                      params: [
                        {
                          key: "publicationId",
                          value: props.publication.id,
                        },
                      ],
                    },
                  });
                  questionRef.value = "";
                });
            } else {
              let messageText = t(
                "publications.faqs.checkContentProcess.dialogs.content.default"
              );
              if (result.personalInformationPresent) {
                messageText = t(
                  "publications.faqs.checkContentProcess.dialogs.content.personalInformationDetected"
                );
              }
              if (result.badSentimentPresent || result.badWordPresent) {
                messageText = t(
                  "publications.faqs.checkContentProcess.dialogs.content.badSentimentsDetected"
                );
              }
              dialog.warning({
                showIcon: false,
                content: messageText,
                positiveText: t(
                  "publications.faqs.checkContentProcess.dialogs.positiveButton.text"
                ),
                onPositiveClick: () => {},
              });
            }
          })
          .catch((error) => {
            console.error(error);
            message.error(
              t("publications.faqs.checkContentProcess.messages.contents.error")
            );
          })
          .finally(() => {
            sendingRef.value = false;
          });
      },
      question: questionRef,
      sending: sendingRef,
      isAuthenticated: computed(() => store.getters["auth/is_authenticated"]),
    };
  },
});
</script>
