<template>
  <n-timeline-item
    type="success"
    :content="value.question"
    :time="formatDate(value.createdAt)"
  >
    <template #header>
      <n-space justify="space-between">
        {{ t("publications.faqs.timeline.question.label") }}:
        <n-button v-if="canDelete" circle size="tiny" @click="remove">
          <template #icon>
            <close-icon />
          </template>
        </n-button>
      </n-space>
    </template>
    <template #icon>
      <n-icon size="25">
        <QuestionIcon />
      </n-icon>
    </template>
  </n-timeline-item>
  <n-timeline-item
    type="primary"
    :title="`${t('publications.faqs.timeline.answer.label')}:`"
    :content="value.answer"
    :time="answered ? formatDate(value.updatedAt) : ''"
    v-if="answered || canAnswer"
  >
    <template #icon>
      <n-icon size="25">
        <AnswerIcon />
      </n-icon> </template
    ><n-list v-if="!answered && canAnswer">
      <n-spin :show="sending">
        <n-list-item>
          <template #suffix>
            <n-button
              type="primary"
              @click="sendAnswer"
              :disabled="answer.length === 0"
              >{{ t("commons.actions.send") }}</n-button
            >
          </template>
          <n-input
            class="pt-2"
            v-model:value="answer"
            type="textarea"
            :placeholder="t('publications.faqs.timeline.answer.placeholder')"
          /> </n-list-item
      ></n-spin>
    </n-list>
  </n-timeline-item>
</template>

<script>
import { defineComponent, computed, ref } from "vue";
import {
  ArrowRedoCircleOutline as QuestionIcon,
  ArrowUndoCircleOutline as AnswerIcon,
  Close as CloseIcon,
} from "@vicons/ionicons5";
import {
  NTimelineItem,
  NIcon,
  NList,
  NListItem,
  NButton,
  NInput,
  useMessage,
  NSpin,
  useDialog,
  NSpace,
} from "naive-ui";
import { useStore } from "vuex";
import { formatDates, formatAMPM } from "@/shared/utils";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "QuestionAnswerTemplate",
  props: {
    value: {
      type: Object,
      required: true,
    },
    publication: {
      type: Object,
      required: true,
    },
  },
  components: {
    QuestionIcon,
    AnswerIcon,
    CloseIcon,
    NTimelineItem,
    NIcon,
    NList,
    NListItem,
    NButton,
    NInput,
    NSpin,
    NSpace,
  },
  setup(props) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });
    const store = useStore();
    const dialog = useDialog();
    const answerRef = ref("");
    const message = useMessage();
    const sendingRef = ref(false);

    const canAnswerRef = computed(
      () => props.publication.user === store.getters["auth/user_id"]
    );
    const answeredRef = computed(
      () => props.value.answer && props.value.answer.length > 0
    );

    return {
      t,
      canAnswer: canAnswerRef,
      answered: answeredRef,
      sendAnswer: () => {
        sendingRef.value = true;

        store
          .dispatch("questions/checkContent", answerRef.value)
          .then((result) => {
            if (result.pass) {
              store
                .dispatch("questions/addAnswer", {
                  id: props.value.id,
                  answer: answerRef.value,
                })
                .then(async () => {
                  message.success(t("publications.faqs.answers.messages.success"));
                  await store.dispatch("notifications/FireNotification", {
                    userId: props.value.relatedUsers.filter(
                      (user) => user != store.getters["auth/user_id"]
                    )[0],
                    fromUserId: store.getters["auth/user_id"],
                    title: t(
                      "publications.faqs.answers.notifications.answerReceived.title",
                      { publicationTitle: props.publication.title }
                    ),
                    message: answerRef.value,
                    avatar: {
                      publicationID: props.publication.id,
                      publicationUser: props.publication.user,
                      defaultImage: props.publication.orderPhotos[0],
                    },
                    targetRoute: {
                      name: "view-publication",
                      params: [
                        {
                          key: "publicationId",
                          value: props.value.publicationID,
                        },
                      ],
                    },
                  });
                });
            } else {
              let messageText = t(
                "publications.faqs.checkContentProcess.dialogs.content.default"
              );
              if (result.personalInformationPresent) {
                messageText = t(
                  "publications.faqs.checkContentProcess.dialogs.content.personalInformationDetected"
                );
              }
              if (result.badSentimentPresent || result.badWordPresent) {
                messageText = t(
                  "publications.faqs.checkContentProcess.dialogs.content.badSentimentsDetected"
                );
              }
              dialog.warning({
                showIcon: false,
                content: messageText,
                positiveText: t(
                  "publications.faqs.checkContentProcess.dialogs.positiveButton.text"
                ),
                onPositiveClick: () => {},
              });
            }
          })
          .catch((error) => {
            console.error(error);
            message.error(
              t("publications.faqs.checkContentProcess.messages.contents.error")
            );
          })
          .finally(() => {
            sendingRef.value = false;
          });
      },
      answer: answerRef,
      sending: sendingRef,
      formatDate: (date) => {
        return `${formatDates(date)} ${formatAMPM(date)} `;
      },
      canDelete: computed(() => {
        return canAnswerRef.value && !answeredRef.value;
      }),
      remove: () => {
        dialog.warning({
          title: t("publications.faqs.timeline.dialogs.deleteConfirmation.title"),
          content: t("publications.faqs.timeline.dialogs.deleteConfirmation.content"),
          positiveText: t("commons.confirmation.yes"),
          negativeText: t("commons.confirmation.no"),
          onPositiveClick: () => {
            store
              .dispatch("questions/deleteQuestion", {
                id: props.value.id,
                version: props.value._version,
              })
              .then(async () => {
                message.success(t("publications.faqs.timeline.messages.deleteSuccess"));
              });
          },
        });
      },
    };
  },
});
</script>
