<template>
  <Input v-if="loaded && !canAnswer" :publication="publication" /><Timeline
    v-if="loaded"
    :publication="publication"
    :filter="filter"
  />
</template>
<style lang="scss" scoped>
* {
  text-align: left;
}
</style>

<script>
import { defineComponent, onMounted, computed, ref } from "vue";
import Input from "@/components/publication/questions/Input.vue";
import Timeline from "@/components/publication/questions/Timeline";
import { useStore } from "vuex";

export default defineComponent({
  name: "Questions",
  components: { Input, Timeline },
  props: {
    publication: {
      type: Object,
      required: true,
    },
    filter: {
      type: String,
      required: false,
      default: "all",
      validator(value) {
        return ["all", "pending"].includes(value);
      },
    },
  },
  setup(props) {
    const store = useStore();
    const loadedRef = ref(false);
    onMounted(() => {
      store.dispatch("questions/fillQuestions", props.publication.id).then(() => {
        loadedRef.value = true;
      });
    });
    return {
      canAnswer: computed(() => props.publication.user === store.getters["auth/user_id"]),
      loaded: loadedRef,
      filterReact: props.filter,
    };
  },
});
</script>
