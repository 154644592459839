<template>
  <n-empty
    class="pt-4"
    v-if="filter === 'pending' && questions.length === 0"
    :description="t('commons.lists.noData')"
  >
  </n-empty>
  <n-card
    v-if="questions.length > 0"
    :class="canAnswer ? 'pt-3' : ''"
    :title="t('publications.faqs.timeline.title')"
    :bordered="false"
  >
    <n-scrollbar style="max-height: 400px" class="pl-1"
      ><n-timeline class="pt-1">
        <QuestionAnswerTemplate
          v-for="question in questions"
          :key="question.id"
          :value="question"
          :publication="publication"
        /> </n-timeline></n-scrollbar
  ></n-card>
</template>
<style lang="scss" scoped>
:deep(.n-card-header),
:deep(.n-card__content) {
  padding-left: 0px;
  padding-right: 0px;
  @media screen and (max-width: 799px) {
    padding-left: 3px;
  }
}
:deep(.n-scrollbar-content) {
  padding-left: 3px;
  padding-right: 10px;
}
* {
  text-align: left;
}
</style>

<script>
import { defineComponent, computed } from "vue";
import { NTimeline, NCard, NEmpty, NScrollbar } from "naive-ui";
import QuestionAnswerTemplate from "@/components/publication/questions/QuestionAnswerTemplate";
import { useStore } from "vuex";
import { parseISO, compareAsc } from "date-fns";
import { useI18n } from "vue-i18n";
export default defineComponent({
  name: "Timeline",
  props: {
    publication: {
      type: Object,
      required: true,
    },
    filter: {
      type: String,
      required: false,
      default: "all",
      validator(value) {
        return ["all", "pending"].includes(value);
      },
    },
  },
  components: {
    NTimeline,
    QuestionAnswerTemplate,
    NCard,
    NEmpty,
    NScrollbar,
  },
  setup(props) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });
    const store = useStore();

    return {
      t,
      questions: computed(() => {
        let questions = store.state.questions.list.filter(
          (question) => question._deleted != true
        );
        if (props.filter === "pending") {
          questions = questions.filter(
            (question) => !(question.answer && question.answer.length > 0)
          );
        }
        return questions.sort((a, b) => {
          return compareAsc(parseISO(b.createdAt), parseISO(a.createdAt));
        });
      }),
      canAnswer: computed(() => props.publication.user === store.getters["auth/user_id"]),
    };
  },
});
</script>
